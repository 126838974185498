import React, { Component } from 'react';
import "./users.css";

class AddSwitchesToUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
       switches: [],
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getswitches`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((switches) => {
      if (this._isMounted) {
        this.setState({ 
          switches: switches,
        })
        switches.forEach(s => 
          this.setState({ [s.num_id]: JSON.parse(this.props.user.visible_sw).includes(s.num_id) })
        )
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  onChange(key, value) {
    this.setState({ [key]: value })
  }

  handelSubmit = () => {
    const checkedSwitches = this.state.switches.map(s => ({ id: s.num_id, checked: this.state[s.num_id] }));
    fetch(`${this.props.url}/api/setvisible`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        password: process.env.REACT_APP_API_PASSWORD,
        switches: checkedSwitches.filter(s=>s.checked),
        id: this.props.user.id
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload({ adminRoute: "users" });
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h1>Add Switches</h1>
          <div className="checklist">
            <table id="t01" align="center">
              <thead>
                <tr style={{ width: "100%", position: "sticky" }}>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "30%" }}>Name</th>
               </tr>
              </thead>
              <tbody>
                {this.state.switches.map((value, i) => (
                  <tr style={{ width: "100%" }} key={i}>
                    <td style={{ width: "5%" }}>
                      <input
                        onChange={(e) => this.onChange(value.num_id, e.target.checked)}
                        type='checkbox'
                        style={{ height: "20px", width: "20px" }}
                        checked={this.state[value.num_id] || false}
                      />
                    </td>
                    <td style={{ width: "30%", textAlign: "start", paddingLeft: "15px" }}>{value.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div><br/><br/>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} className='submit_button'>Submit</button>
            <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default AddSwitchesToUser
