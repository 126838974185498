import React, { Component } from 'react'

class AllOffWarning extends Component {
  handelSubmit = () => {
    this.props.reset()
    fetch(`${this.props.url}/api/getswitches`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((switches) => {
      var s = switches;
      if(this.props.area === true) {
        s = this.props.switches;
      }
      s.filter(s=>s.all_off === 0 || !this.props.filter).forEach(switchItem => {
        fetch(`${this.props.url}/api/toggle`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: process.env.REACT_APP_API_PASSWORD,
            val: 0,
            ip_addr: switchItem.ip_addr,
            gpio: switchItem.instance ? "gpio" + switchItem.instance : "gpio"
          }),
        }).catch((err) => console.log(err));
      })
      alert("operation successful!!");
      if (this.props.area === false) {
        if (window.location.href.includes("home")) this.props.reload();
      } else {
        if (window.location.href.includes("home")) this.props.reload({homeRoute: this.props.route});
      }
    }).catch((err) => console.log(err));
  }

  render() {
    const isArea = this.props.area === true;;
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h3 style={{ lineHeight: "20px" }}>
            All switches will{isArea ? " in area" : ""} be closed.<br />Are you sure?
          </h3>
          <div style={{ display: "flex" }}>
            <button className='submit_button' onClick={this.handelSubmit}>Yes</button>
            <button className='cancel_button' onClick={this.props.reset}>No</button>
          </div>
        </div>
      </div>
    )
  }
}

export default AllOffWarning;
