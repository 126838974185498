import React, { Component } from 'react'

class AddArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      areas: [],
      order: "0"
    }
  }
  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  handelSubmit = () => {
    this.state.areas.filter(a=>a.order>=Number(this.state.order)).forEach(area => {
      fetch(`${this.props.url}/api/editarea`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          id: area.id,
          order: area.order+1,
          name: area.name
        }),
      }).catch((err) => console.log(err));
    });
    fetch(`${this.props.url}/api/addarea`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        name: this.state.name,
        order: Number(this.state.order)
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload({adminRoute: "areas"});
    }).catch((err) => console.log(err));
  }
  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getareas`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((areas) => {
      if (this._isMounted) {
        this.setState({ areas: areas, order: areas.length+1 })
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <div className='action_container'>
        <br/>
        <h1 className='action_header'>Add Area:</h1>
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='name'
            type="text"
            onChange={this.handelChange}
          />
          <span>Area Name</span>
        </label>
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='order'
            type="number"
            onChange={this.handelChange}
            value={this.state.order}
            min={1}
            max={this.state.areas.length+1}
          />
          <span>Order</span>
        </label>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={this.handelSubmit} className='submit_button'>Add</button>
          <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
        </div>
      </div>
    )
  }
}

export default AddArea
