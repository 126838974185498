import React, { Component } from 'react'
import Row from './row';
import Timer from '../../components/timer/timer';
import EditTimer from '../../components/timer/editTimer';
import AllOffWarning from '../../components/menu/allOffWarning';

class SwitchesList extends Component {
  constructor(props) {
    super();
    this.state = {
      addTimer: false,
      editTimer: false,
      selected: {},
      timer: {},
      screenOrientation: "portrait",
      allOff: false,
    };
  }

  timer = (s) => {
    if (!this.state.addTimer) {
      this.props.openPop();
    } else {
      this.props.closePop();
    }
    this.setState({ addTimer: !this.state.addTimer, selected: s });
  };
  editTimer = (s, t) => {
    if (!this.state.addTimer) {
      this.props.openPop();
    } else {
      this.props.closePop();
    }
    this.setState({ editTimer: !this.state.editTimer, selected: s, timer: t });
  };
  allOff = (s, t) => this.setState({ allOff: !this.state.allOff });

  setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches)
      this.setState({ screenOrientation: "portrait" });

    if (window.matchMedia("(orientation: landscape)").matches)
      this.setState({ screenOrientation: "landscape" });
  };

  componentDidMount() {
    window.addEventListener("resize", this.setScreenOrientation);
    this.setScreenOrientation();
  }

  render() {
    const { url, route, reload, user, area } = this.props;

    let switches = this.props.switches;

    if (!user.is_root) {
      switches = switches.filter((s) =>
        JSON.parse(user.visible_sw).includes(s.num_id)
      );
    }
    switches = switches.filter((s) => s.area_id === area);

    return (
      <div>
        <button 
          className="submit_button" 
          onClick={this.allOff}
          style={{backgroundColor: "white", padding: "5px", position: "relative", bottom: "-15px", left: "-25%"}}
        >
          Area All Off
        </button>
        <table
          id="t01"
          align="center"
          style={{ width: "80%", marginTop: "25px" }}
        >
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Switch Name</th>
              <th style={{ width: "30px" }}></th>
              <th style={{ width: "30px" }}></th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          {switches
            .sort((a, b) => a.order - b.order)
            .map((switchItem) => {
              return (
                <Row
                  switchItem={switchItem}
                  key={switchItem.num_id}
                  url={url}
                  route={route}
                  reload={reload}
                  user={user}
                  timer={this.timer}
                  editTimer={this.editTimer}
                  screenOrientation={this.state.screenOrientation}
                />
              );
            })}
        </table>
        {this.state.addTimer ? (
          <Timer
            switchItem={this.state.selected}
            reset={() => this.timer({})}
            url={url}
            reload={reload}
            route={route}
          />
        ) : null}
        {this.state.editTimer ? (
          <EditTimer
            switchItem={this.state.selected}
            reset={() => this.editTimer({}, "")}
            url={url}
            reload={reload}
            route={route}
            timer={this.state.timer}
          />
        ) : null}
        {this.state.allOff ? (
          <AllOffWarning
            switches={switches}
            reset={() => this.allOff()}
            area={true}
            url={url}
            reload={reload}
            route={route}
            filter={false}
          />
        ) : null}
      </div>
    );
  }
}

export default SwitchesList;