import React, { Component } from 'react';
import "./signpage.css";

class SignPage extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        user: "",
        pass: ""
     }
  }
  componentDidMount() {
    if(this.props.isLog) {
      window.open("/home", "_self");
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  login = () => {
    console.log(`${this.props.url}/api/encrypt_pass`)
    fetch(`${this.props.url}/api/encrypt_pass`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: this.state.user,
        pass: this.state.pass,
        password: process.env.REACT_APP_API_PASSWORD,
      })
    }).then(res => res.json()).then(({pass}) => {
      fetch(`${this.props.url}/api/login`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: this.state.user,
          pass: pass,
          password: process.env.REACT_APP_API_PASSWORD,
        })
      }).then(res => res.json()).then((user) => {
        console.log(user)
        if(user !== "no user") {
          localStorage.setItem("userdata", user.username + "," + pass)
          window.open("/home", "_self")
        }
      }).catch(err => console.log(err));
    }).catch(err => console.log(err));
  }

  render() { 
    return (
      <div className='signpage'>
        <h1>Smart Home Login</h1>
        <label>
          <input 
            className='popup_text' 
            placeholder=" " 
            maxLength='255' 
            name='user' 
            onChange={this.handelChange}
          />
          <span>Username</span>
        </label><br/>
        <label>
          <input 
            className='popup_text' 
            placeholder=" "
            maxLength='255'
            name='pass'
            type="password"
            onChange={this.handelChange}
          />
          <span>Password</span>
        </label><br />
        <button onClick={this.login}>Submit</button>
      </div>
    );
  }
}
 
export default SignPage;