import React, { Component } from 'react';

const days = [1,2,3,4,5,6,7];
var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}
const toTimeFormat = (date) => {
  return twoDigits(date.getHours()) + ":" + twoDigits(date.getMinutes());
};

const formatdate = (d) => {
  return twoDigits(d.getFullYear()) + "-" + twoDigits(Number(d.getMonth())+1) + "-" + twoDigits(d.getDate())
}

class AddScheduler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: toTimeFormat(new Date()),
      end: toTimeFormat(new Date()),
      days: "daily",
      date: formatdate(new Date()),
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
      switchItem: {},
      type: 2
    };
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getswitchbyid`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD, 
        id: localStorage.getItem("sc_sw") 
      }),
    }).then((res) => res.json()).then((sw) => {
      if (this._isMounted) {
        this.setState({ switchItem: sw });
      }
    }).catch((err) => console.log(err));
  }
    componentWillUnmount() {
    this._isMounted = false
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChangeChk = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };
  
  handelSubmit = () => {
    let pickedDays = dayNames.map((d, i)=>{
      return (this.state[d] ? days[i] : false)
    })
    fetch(`${this.props.url}/api/addschedule`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        password: process.env.REACT_APP_API_PASSWORD, 
        start: (Number(this.state.type) === 0 ? "" : this.state.start),
        end: (Number(this.state.type) === 1 ? "" : this.state.end),
        switchId: this.state.switchItem.num_id,
        days: this.state.days==="date" 
          ? this.state.date 
          : (this.state.days==="daily" ? 1 : JSON.stringify(pickedDays.filter(d=>d))),
        dayType: this.state.days
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload();
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='action_container'>
        <br/>
        <h1 className='action_header'>Add Scheduler: {this.state.switchItem.name}</h1>
        <div style={{marginLeft: "50px"}}>
          <div className='time'>
            <h3>Mode</h3>
            <label>
              <select name='type' onChange={this.handelChange} className='popup_text select' style={{ marginLeft: "60px", width: "150px"}} value={this.state.type}>
                <option value="2">Both</option>
                <option value="1">On</option>
                <option value="0">Off</option>
              </select>
            </label>
          </div>
          <div className='time'>
            <h3>Start</h3>
            <input
              placeholder=" "
              maxLength="11"
              name="start"
              type="time"
              onChange={this.handelChange}
              style={{marginLeft: "70px"}}
              value={this.state.start}
              disabled={Number(this.state.type) === 0}
            />
          </div>
          <br />
          <div className='time'>
            <h3>End</h3>
            <input
              placeholder=" "
              maxLength="11"
              name="end"
              type="time"
              onChange={this.handelChange}
              style={{ marginLeft: "80px" }}
              value={this.state.end}
              disabled={Number(this.state.type)  === 1}
            />
          </div>
          <br />
          <div style={{display: "flex", justifyContent: "left"}}>
            <input
              maxLength="11"
              name="days"
              type="radio"
              value="daily"
              onChange={this.handelChange}
              checked={this.state.days==="daily"}
              id="daily"
              style={{width: "10px"}}
            />
            <label 
              htmlFor="daily" 
              style={{ width: "40px", marginBottom: "0px", fontWeight: "bold" }}
            >
              Daily
            </label>
          </div>
          <div style={{display: "flex", justifyContent: "left"}}>
            <input
              maxLength="11"
              name="days"
              type="radio"
              value="date"
              onChange={this.handelChange}
              checked={this.state.days==="date"}
              id="once"
              style={{ width: "10px", height: "50px" }}
            />
            <h3 htmlFor="once" style={{ width: "40px" }}>Once</h3>
            <input
              name="date"
              type="date"
              onChange={this.handelChange}
              style={{height: "20px", marginTop: "20px", marginLeft: "90px"}}
              value={this.state.date}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "left"}}>
            <input
              maxLength="11"
              name="days"
              type="radio"
              value="days"
              onChange={this.handelChange}
              checked={this.state.days==="days"}
              id="days"
              style={{width: "10px", height: "50px"}}
            />
            <h3 htmlFor="days">Days</h3>
            <div className='days' style={{marginLeft: "70px"}}>
              {
                days.map((day, i)=>{
                  return (
                    <div key={i} style={{display: "flex"}}>
                      <input
                        type="checkbox"
                        onChange={this.handleChangeChk}
                        value={day}
                        name={dayNames[i]}
                      />
                      <p>{dayNames[i]}</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={this.handelSubmit} className="submit_button">Add</button>
          <button onClick={this.props.reset} className="cancel_button">Cancel</button>
        </div>
      </div>
    );
  }
}

export default AddScheduler
