import React, { Component } from 'react'

class DeleteScheduler extends Component {
  handelSubmit = () => {
    this.props.reset()
    fetch(`${this.props.url}/api/deleteschedule`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        id: this.props.schedule.id
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload()
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h3 style={{ lineHeight: "20px" }}>
            Schedule -<br />{this.props.schedule.name}<br />will be deleted.<br />Are you sure?
          </h3>
          <div style={{ display: "flex" }}>
            <button className='submit_button' onClick={this.handelSubmit}>Yes</button>
            <button className='cancel_button' onClick={this.props.reset}>No</button>
          </div>
        </div>
      </div>
    )
  }
}

export default DeleteScheduler;
