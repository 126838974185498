import React, { Component } from 'react';
import EditFavorite from '../../components/editFavorite/editFavorite';

class Card extends Component {
  constructor(props) {
    super()
    this.state = {
      status: { component: { value: "" }, broken: true },
      edit: false
    }
  }
  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getstatus`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        ip_addr: this.props.switchItem.ip_addr,
        gpio: this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio",
        port: this.props.switchItem.port
      }),
    }).then((res) => res.json()).then((status) => {
      if (this._isMounted && status) {
        this.setState({ status })
      } else {
        if (this._isMounted) {
          this.setState({ status: { component: { value: "" }, broken: true } })
        }
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  toggle = (val) => {
    fetch(`${this.props.url}/api/toggle`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        val: val,
        ip_addr: this.props.switchItem.ip_addr,
        gpio: this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio",
        port: this.props.switchItem.port
      }),
    }).then((res) => res.json()).then((status) => {
      this.componentDidMount();
    }).catch((err) => console.log(err));
  }
  editFav = () => {
    this.setState({edit: !this.state.edit});
  }

  createFav = () => {
    let favorites = JSON.parse(this.props.user.favorites);

    if (favorites.map(e => e.id).includes(this.props.switchItem.num_id)) {
      favorites = favorites.filter(item => item.id !== this.props.switchItem.num_id).sort((a, b) => a.order - b.order);
      favorites = favorites.map((item, i) => ({ id: item.id, order: i + 1, type: "switch" }))
    } else {
      favorites.push({ id: this.props.switchItem.num_id, order: favorites.length + 1 })
    }
    fetch(`${this.props.url}/api/setfav`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        favorites: favorites,
        id: this.props.user.id
      }),
    }).then((res) => res.json()).then((status) => {
      alert(!favorites.map(e => e.id).includes(this.props.switchItem.num_id) ? "Favorite was removes" : "Switch was added to favorites")
      this.props.reload({ homeRoute: this.props.route })
    }).catch((err) => console.log(err));
  }

  render() {
    const { switchItem, url, reload, user } = this.props;
    const { status } = this.state;

    return (
      <div className="card" onDoubleClick={this.createFav}>
        <button className='menu_dotes' onClick={(e)=>{e.stopPropagation();this.editFav()}}/>
        <h6 onContextMenu={this.createFav} onDoubleClick={(e)=>{e.stopPropagation();this.createFav()}}>
          {switchItem.name.substring(0, 20)}
          {switchItem.name.length > 20 ? "..." : ""}
        </h6>
        <button 
          className={(this.state.status.broken ? "broken" : (Number(status.component.value) ? "on" : "off")) + " lamp"}
          onClick={() => this.toggle(Number(status.component.value) ? "0" : "1")}
        />
        {this.state.edit
          ?<EditFavorite
              url={url}
              reload={reload}
              user={user}
              reset={this.editFav}
              switchItem={switchItem}
              route={this.props.route}
              areas={this.props.areas}
            />
          : null
        }
      </div>
    )
  }
}

export default Card
