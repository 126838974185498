import React, { Component } from 'react'
import DeleteSwitch from './deleteSwitch';
import EditSwitch from './editSwitch';

class SwitchesRoute extends Component {
  constructor() {
    super()
    this.state = {
      switches: [],
      editSwitch: false,
      deleteSwitch: false,
      scheduler: false,
      areas: [],
      area: "all",
      name: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getswitches`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((switches) => {
      fetch(`${this.props.url}/api/getareas`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
      }).then((res) => res.json()).then((areas) => {
        if (this._isMounted) {
          this.setState({ areas: areas, switches: switches })
        }
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { url, reload, setRoute } = this.props;
    
    return (
      <div>
        <button
          className="add"
          style={{ margin: "15px", position: "absolute", left: "10px" }}
          onClick={() => setRoute("addSwitch")}
        />
        <button
          className="version"
          style={{ margin: "15px", position: "absolute", left: "50px" }}
          onClick={() => setRoute("versionReport")}
        />
        <br /><br /><br />
        <label style={{width: "40%"}}>
          <select name='area' onChange={(e)=>this.setState({area: e.target.value})} className='popup_text select' style={{width: "100%"}}>
            <option value="all">All</option>
            {
              this.state.areas.map(area=>{
              return <option key={area.id} value={area.id}>{area.name}</option>
              })
            }
          </select>
        </label>
        <label style={{width: "40%"}}>
          <input
            className='popup_text'
            placeholder=" "
            name='name'
            type="text"
            onChange={(e)=>this.setState({name: e.target.value})}
            style={{width: "100%"}}
          />
          <span>Search By Name</span>
        </label>
        <div>
          <table id="t01" align="center">
            <thead>
              <tr>
                <th style={{ width: "60%" }}>Switch Name</th>
                <th style={{ width: "60%" }}>Area Name</th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "20%" }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.switches
                .filter(s=>this.state.area==="all" || Number(this.state.area) === s.area_id)
                .filter(s=>s.name.includes(this.state.name))
                .sort((a, b) => a.num_id - b.num_id)
                .map((switchItem) => {
                  return (
                    <tr key={switchItem.num_id}>
                      <td style={{ width: "60%" }}>{switchItem.name}</td>
                      <td style={{ width: "60%" }}>{switchItem.areaName}</td>
                      <td style={{ width: "20%" }}>
                        <button
                          className="edit"
                          onClick={() =>this.setState({editSwitch: true, selected: switchItem})}
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        <button
                          className="delete"
                          onClick={() => this.setState({ deleteSwitch: true, selected: switchItem})}
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        {switchItem.sw_type !== 1
                          ?<button
                            className="clock_icon"
                            onClick={() => {setRoute("scheduler", switchItem);localStorage.setItem("sc_sw", switchItem.num_id)}}
                          />
                          : null
                        }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {this.state.deleteSwitch ? (
          <DeleteSwitch
            switch={this.state.selected}
            reload={reload}
            url={url}
            reset={() => this.setState({ deleteSwitch: false })}
          />
        ) : null}
        {this.state.editSwitch ? (
          <EditSwitch
            switch={this.state.selected}
            switches={this.state.switches.sort((a, b) => a.num_id - b.num_id)}
            areas={this.state.areas}
            reload={reload}
            url={url}
            reset={() => this.setState({ editSwitch: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default SwitchesRoute;
