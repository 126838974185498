import React, { Component } from 'react'

class SetAllOff extends Component {
  constructor(props) {
    super();
    this.state = {
      switches: [],
      areas: [],
      name: "",
      area: "all"
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getswitches`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((switches) => {
      fetch(`${this.props.url}/api/getareas`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
      }).then((res) => res.json()).then((areas) => {
        if (this._isMounted) {
          this.setState({
            switches: switches,
            areas: areas
          })
          switches.forEach(s =>
            this.setState({ [s.num_id]: s.all_off })
          )
        }
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  onChange(key, value) {
    this.setState({ [key]: value })
  }

  handelSubmit = () => {
    const checkedSwitches = this.state.switches.map(s => ({ id: s.num_id, checked: this.state[s.num_id] }));
    fetch(`${this.props.url}/api/setexeption`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        switches: checkedSwitches,
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload({ adminRoute: "switches" });
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div>
        <h1>Set All-Off Exeptions</h1>
        <label style={{ width: "40%" }}>
          <select name='area' onChange={(e) => this.setState({ area: e.target.value })} className='popup_text select' style={{ width: "100%" }}>
            <option value="all">All</option>
            {
              this.state.areas.map(area => {
                return <option key={area.id} value={area.id}>{area.name}</option>
              })
            }
          </select>
        </label>
        <label style={{ width: "40%" }}>
          <input
            className='popup_text'
            placeholder=" "
            name='name'
            type="text"
            onChange={(e) => this.setState({ name: e.target.value })}
            style={{ width: "100%" }}
          />
          <span>Search By Name</span>
        </label>
        <div className="checklist">
          <table id="t01" align="center">
            <thead>
              <tr style={{ width: "100%", position: "sticky" }}>
                <th style={{ width: "5%" }}></th>
                <th style={{ width: "30%" }}>Name</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.switches
                  .filter(s => this.state.area === "all" || Number(this.state.area) === s.area_id)
                  .filter(s => s.name.includes(this.state.name))
                  .filter(s => s.sw_type !== 1)
                  .map((value, i) => (
                  <tr style={{ width: "100%" }} key={i}>
                    <td style={{ width: "5%" }}>
                      <input
                        onChange={(e) => this.onChange(value.num_id, e.target.checked)}
                        type='checkbox'
                        style={{ height: "20px", width: "20px" }}
                        checked={this.state[value.num_id] || false}
                      />
                    </td>
                    <td style={{ width: "30%", textAlign: "start", paddingLeft: "15px" }}>{value.name}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div><br /><br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={this.handelSubmit} className='submit_button'>Submit</button>
        </div>
      </div>
    )
  }
}

export default SetAllOff
