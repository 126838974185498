import React, { Component } from 'react'

class UserDelete extends Component {
  handelSubmit = () => {
    this.props.reset()
    fetch(`${this.props.url}/api/deleteuser`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: this.props.user.id, password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((users) => {
      this.props.reload({ adminRoute: "users" });
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{padding: "30px"}}>
          <h3 style={{lineHeight: "20px"}}>
            User -<br/>{this.props.user.username}<br/>will be deleted.<br/>Are you sure?
          </h3>
          <div style={{display: "flex"}}>
            <button className='submit_button' onClick={this.handelSubmit}>Yes</button>
            <button className='cancel_button' onClick={this.props.reset}>No</button>
          </div>
        </div>
      </div>
    )
  }
}

export default UserDelete;
