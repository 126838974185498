import React, { Component } from 'react';
function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}

var getDuration = function (d) {
  var d3 = new Date(d - new Date());
  var d0 = new Date(0);

  return {
    getHours: function () {
      return d3.getHours() - d0.getHours();
    },
    getMinutes: function () {
      return d3.getMinutes() - d0.getMinutes();
    },
    getSeconds: function () {
      return d3.getSeconds() - d0.getSeconds();
    },
    toString: function () {
      return  twoDigits(this.getHours()) + ":" +
        twoDigits(this.getMinutes()) + ":" +
        twoDigits(this.getSeconds());
    },
    toStringPor: function () {
      return  twoDigits((this.getMinutes() + this.getHours()*60))  + ":" + twoDigits(this.getSeconds())
    }
  };
}

const formatdate2 = (s, por) => {
  if(por) {
    return getDuration(new Date(new Date().toDateString() + " " + s.end)).toStringPor();
  }
  return getDuration(new Date(new Date().toDateString() + " " + s.end)).toString();
}

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {component:{value:""}},
      timer: false,
      extra: false,
      fanStatus: {component:{value:""}}
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.reload();
    this.reload(1);
    // get schedule
    fetch(`${this.props.url}/api/getschedules`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        id: this.props.switchItem.num_id
      }),
    }).then((res) => res.json()).then((shcedules) => {
      if (this._isMounted) {
        const timer = shcedules.filter(s => s.timer).length ? shcedules.filter(s => s.timer)[0] : false;
        this.setState({ timer })
        if (timer) {
          this.timerID = setInterval(() => {
            if (new Date(new Date().toLocaleDateString() + " " + this.state.timer.end) < new Date()) {
              this.props.reload({ homeRoute: this.props.route })
              clearInterval(this.timerID);
            } else {
              this.forceUpdate()
            }
          }, 1000);
        } else {
          this.timerID = setInterval(() => this.reload(), 30000);
        }
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  reload = (fan=0) => {
    fetch(`${this.props.url}/api/getstatus`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        ip_addr: fan===1 ? this.props.switchItem.second_ip : this.props.switchItem.ip_addr,
        gpio: fan===1
                ? this.props.switchItem.second_instance ? "gpio" + this.props.switchItem.second_instance : "gpio"
                : this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio",
        port: fan===0 ? this.props.switchItem.port : this.props.switchItem.second_port,
      }),
    }).then((res) => res.json()).then((status) => {
      console.log(status, "STATUS")
      if (this._isMounted && status) {
        if(fan===1)
          this.setState({fanStatus: status})
        else 
          this.setState({ status })
      }
    }).catch((err) => console.log(err));
  }

  toggle = (val, fan=0) => {
    console.log(fan);
    if(this.props.switchItem.sw_type===4 ? (Number(this.state.status.component.value) || fan===0) : 1) {
      this.forceUpdate();
      this._isMounted = true;
      fetch(`${this.props.url}/api/toggle`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          val: val,
          ip_addr: fan===1 ? this.props.switchItem.second_ip :this.props.switchItem.ip_addr,
          gpio: fan===1
                ? this.props.switchItem.second_instance ? "gpio" + this.props.switchItem.second_instance : "gpio"
                : this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio",
          port: fan===1 ? this.props.switchItem.second_port : this.props.switchItem.port,
        }),
      }).then((res) => res.json()).then(() => {
        if (this._isMounted) {
          this.reload();
          this.reload(1);
        }
      }).catch((err) => console.log(err)); 
    }
  }

  createFav = () => {
    let favorites = JSON.parse(this.props.user.favorites);

    if (favorites.map(e=>e.id).includes(this.props.switchItem.num_id)) {
      favorites = favorites.filter(item => item.id !== this.props.switchItem.num_id).sort((a,b)=>a.order-b.order);
      favorites = favorites.map((item, i)=>({id: item.id, order: i+1, type:"switch"}))
    } else {
      favorites.push({id: this.props.switchItem.num_id, order: favorites.length+1, type:"switch"})
    }
    fetch(`${this.props.url}/api/setfav`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        favorites: favorites,
        id: this.props.user.id,
      }),
    }).then((res) => res.json()).then((status) => {
      alert(!favorites.map(e=>e.id).includes(this.props.switchItem.num_id) ? "Favorite was removes" : "Switch was added to favorites")
      this.props.reload({ homeRoute: this.props.route })
    }).catch((err) => console.log(err));
  }

  changeExtra = () => this.setState({extra: !this.state.extra})

  changeFanNum = (num) => {
    if(Number(this.state.status.component.value)) {
      fetch(`${this.props.url}/api/changefunnum`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          num: num,
          ip_addr: this.props.switchItem.second_ip,
          gpio: this.props.switchItem.second_instance ? "gpio" + this.props.switchItem.second_instance : "gpio",
          port: this.props.switchItem.second_port,
        }),
      }).then((res) => res.json()).then((status) => {
        if (this._isMounted) {
          this.reload(1);
        }
      }).catch((err) => console.log(err));
    }
  }

  render() {
    const { switchItem, timer, editTimer } = this.props;
    const { status } = this.state;
    const isPassed = new Date(new Date().toLocaleDateString() + " " + this.state.timer.end) < new Date();
    const fanArr = ["OFF", "LOW", "MEDIUM", "HIGH"];
    return <tbody style={{width: "10px"}}>
      <tr key={switchItem.num_id} onDoubleClick={this.createFav}>
        <td style={{ width: "35%" }}>
          {switchItem.name}
        </td>
        {switchItem.sw_type === 1
          ? <td style={{width: "30px"}}>
              {status.component.value === ""
                ? "n/a"
                : ( Number(status.component.value) ? "opened" : "closed" )
              }
            </td>
          :(switchItem.sw_type === 4
            ?<td style={{width: "30px",backgroundColor: Number(this.state.status.component.value) && this.state.fanStatus.component.value !== "" ? "red" : "white"}}>
              <button className={Number(this.state.fanStatus.component.value) ? 'fan' : 'fan_stop'} onClick={() => this.toggle(1)} />
            </td>
            :<td style={{width: "30px",backgroundColor: Number(status.component.value) && status.component.value !== "" ? "red" : "white"}}>
              <button className='on' onClick={() => this.toggle(1)} />
            </td>
          )
        }
        {switchItem.sw_type === 1
          ?  <td style={{ width: "30px" }}>
              <button className='door' onClick={() => this.toggle("short")} />
            </td>
          :(switchItem.sw_type === 4
            ?<td style={{width: "30px", backgroundColor: !Number(this.state.status.component.value) && this.state.fanStatus.component.value !== "" ? "green" :"white"}}>
              <button className='fan_off' onClick={() => this.toggle(0)} />
            </td>
            :<td style={{width: "30px", backgroundColor: !Number(status.component.value) && status.component.value !== "" ? "green" :"white"}}>
              <button className='off' onClick={() => this.toggle(0)} />
            </td>
          )
        }
        <td style={{ width: "100px", backgroundColor: isPassed ? "red" : "white", wordBreak: "keep-all" }}  onClick={this.changeExtra}>
          <button className='arrow_extra' style={this.state.extra ? {transform: "scaleY(-1)"} : {}}/>
        </td>
      </tr>
      <tr className="extra-row" style={this.state.extra ? {marginTop: "0px", visibility: "visible", opacity: "1"} : {marginTop: "-50px"}}>
        {switchItem.sw_type === 4
          ? <td style={{width: "16%",backgroundColor: Number(this.state.fanStatus.component.Light) && this.state.fanStatus.component.Light !== "" && Number(status.component.value) ? "#42bcf5" : "white"}}>
          <button className='on' onClick={() => this.toggle(1, 1)} />
        </td>
          : null
        }
        {switchItem.sw_type === 4
          ?<td style={{width: "16%", backgroundColor: !Number(this.state.fanStatus.component.Light) && this.state.fanStatus.component.Light !== "" && Number(status.component.value) ? "#42bcf5" :"white"}}>
            <button className='off' onClick={() => this.toggle(0, 1)} />
          </td>
          : null
        }
        {switchItem.sw_type === 4
          ?[0,1,2,3].map((num) => {
            return <td key={num} onClick={()=> this.changeFanNum(num)} style={{ padding: "5px", width: "16%", backgroundColor: Number(this.state.fanStatus.component.Fan)===num && Number(status.component.value) ? "#42bcf5" : "white", wordBreak: "keep-all" }}>
              <button className={`num_button fButton${num}`}/><br/>
              <p className="fan_text">{fanArr[num]}</p>
            </td>
          })
          : null
        }
        <td onClick={this.state.timer !== false && !isPassed ? ()=>editTimer(switchItem, this.state.timer) : ()=> timer(switchItem)} style={{backgroundColor: isPassed ? "red" : "white", wordBreak: "keep-all"}} className={this.props.switchItem.sw_type !== 4 ? "max_box" : "r25_box"}>
          {this.state.timer !== false && !isPassed
            ? <p onClick={() => editTimer(switchItem, this.state.timer)}>
                {formatdate2(this.state.timer, this.props.screenOrientation === 'portrait')}
              </p>
            : <button className='clock_icon' onClick={() => timer(switchItem)} disabled={isPassed}/>
          }
        </td>
      </tr>

    </tbody>
  }
}

export default Row
