import React, { Component } from 'react';

class Row extends Component {
  constructor(props) {
    super();
    this.state = {
      ver: ""
    }
  }
  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getver`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        password: process.env.REACT_APP_API_PASSWORD,
        ip_addr: this.props.switchItem.ip_addr,
        port: this.props.switchItem.port,
        gpio: this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio"
      }),
    }).then((res) => res.json()).then((ver) => {
      if (this._isMounted) {
        if (ver.statusCode === 200) {
          this.setState({ ver: JSON.parse(ver.body).component.version })
        }
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  restartSwitch = () => {
    fetch(`${this.props.url}/api/restart`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        password: process.env.REACT_APP_API_PASSWORD,
        ip_addr: this.props.switchItem.ip_addr,
        port: this.props.switchItem.port,
      }),
    }).then((res) => res.json()).then(() => {
      
    }).catch((err) => console.log(err));
  }

  render() {
    const { switchItem } = this.props;

    return <tr>
      <td style={{ width: "20%" }}>{switchItem.name}</td>
      <td style={{ width: "40%" }}>{switchItem.ip_addr}</td>
      <td style={{ width: "40%" }}>{this.state.ver}</td>
      <td style={{ width: "40%" }}><button className='restart' onClick={this.restartSwitch}/></td>
    </tr>
  }
}

class VersionReport extends Component {
  constructor() {
    super()
    this.state = {
      switches: [],
      areas: [],
      area: "all",
      name: ""
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getswitches`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((switches) => {
      fetch(`${this.props.url}/api/getareas`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
      }).then((res) => res.json()).then((areas) => {
        if (this._isMounted) {
          this.setState({ areas: areas, switches: switches })
        }
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { setRoute, url } = this.props;

    return (
      <div>
        <button
          className='close'
          style={{ margin: "15px", position: "absolute", left: "10px" }}
          onClick={() => setRoute("switches")}
        /><br /><br /><br />
        <label style={{width: "40%"}}>
          <select name='area' onChange={(e)=>this.setState({area: e.target.value})} className='popup_text select' style={{width: "100%"}}>
            <option value="all">All</option>
            {
              this.state.areas.map(area=>{
              return <option key={area.id} value={area.id}>{area.name}</option>
              })
            }
          </select>
        </label>
        <label style={{width: "40%"}}>
          <input
            className='popup_text'
            placeholder=" "
            name='name'
            type="text"
            onChange={(e)=>this.setState({name: e.target.value})}
            style={{width: "100%"}}
          />
          <span>Search By Name</span>
        </label>
        <table id="t01" align="center" style={{ width: "100%"}}>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Switch Name</th>
              <th style={{ width: "40%" }}>Switch IP</th>
              <th style={{ width: "40%" }}>Switch Version</th>
              <th style={{ width: "40%" }}>Restart</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.switches
                .filter(s=>this.state.area==="all" || Number(this.state.area) === s.area_id)
                .filter(s=>s.name.includes(this.state.name))
                .sort((a, b) => a.num_id - b.num_id).map(switchItem => {
                return <Row switchItem={switchItem} key={switchItem.id} url={url}/>
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default VersionReport;
