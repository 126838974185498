import React, { Component } from 'react'

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: props.user.username,
      password: props.user.password,
      passwordTwo: props.user.password,
      isAdmin: props.user.is_root,
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  checkChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked })
  }
  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/decrypt`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key: this.state.password, password: process.env.REACT_APP_API_PASSWORD}),
    }).then((res) => res.json()).then((val) => {
      if (this._isMounted) {
        this.setState({ password: val, passwordTwo: val })
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  logOut = () => { localStorage.setItem("userdata", " , "); window.open("/", "_self") }

  handelSubmit = () => {
    const { url, users, reload, user, myUser } = this.props

    if (this.state.password === this.state.passwordTwo && users.filter(u=>u.username===this.state.username && u.id!==user.id).length < 1) {
      fetch(`${url}/api/edituser`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: this.state.username,
          pass: this.state.password,
          isAdmin: this.state.isAdmin ? 1 : 0,
          id: user.id,
          password: process.env.REACT_APP_API_PASSWORD
        }),
      }).then((res) => res.json()).then(() => {
        if(myUser.id === user.id)
          this.logOut();
        else
          reload({ adminRoute: "users" });
      }).catch((err) => console.log(err));
    } else {
      if (this.state.password !== this.state.passwordTwo)
        alert('Errror, password is not valid...');
      else
        alert('Error, username exist...');
    }
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h1 className='action_header'>Edit User:</h1>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='255'
              name='username'
              type="text"
              value={this.state.username}
              onChange={this.handelChange}
              disabled
            />
            <span>User Name</span>
          </label><br />
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='255'
              name='password'
              type="password"
              value={this.state.password}
              onChange={this.handelChange}
            />
            <span>Password</span>
          </label><br />
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='255'
              name='passwordTwo'
              type="password"
              value={this.state.passwordTwo}
              onChange={this.handelChange}
            />
            <span>Repet Password</span>
          </label><br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4 className="admin-text">Admin User</h4>
            <div style={{marginTop: "20px", marginLeft: "5px"}}>
              <input 
                type="checkbox" 
                name="isAdmin" 
                onChange={this.checkChange} 
                checked={this.state.isAdmin}
                disabled={!this.props.myUser.is_root}
              />
            </div>
          </div>
          {!this.state.isAdmin && this.props.myUser.is_root
            ? <button 
                className='submit_button' 
                style={{ backgroundColor: "#0080ff", width: "120px", marginBottom: "10px"}}
                onClick={this.props.addSwitches}
              >
                Add Switches
              </button>
            : null
          }
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} className='submit_button'>Change</button>
            <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditUser
