import React, { Component } from 'react'

class DeleteArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      switches: []
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getswitchesbyarea`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ areaId: this.props.area.id, password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((switches) => {
      if (this._isMounted) {
        this.setState({ switches: switches })
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }


  handelSubmit = () => {
    this.props.reset();

    this.state.switches.forEach(s=>{
      fetch(`${this.props.url}/api/deleteswitch`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: s.num_id,
        })
      })
    })

    fetch(`${this.props.url}/api/deletearea`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: this.props.area.id, password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then(() => {
      this.props.reload({ adminRoute: "areas" });
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "25px" }}>
          <h3 style={{ lineHeight: "20px" }}>
            Area -<br />{this.props.area.name} and all the switches in it<br />will be deleted.<br />Are you sure?
          </h3>
          <div style={{ display: "flex" }}>
            <button className='submit_button' onClick={this.handelSubmit}>Yes</button>
            <button className='cancel_button' onClick={this.props.reset}>No</button>
          </div>
        </div>
      </div>
    )
  }
}

export default DeleteArea;
