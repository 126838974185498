import React, { Component } from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
import './App.css';
import "./icons/icons.css";

import SignPage from './pages/signpage/signpage';
import Home from './pages/home/home';
import Menu from './components/menu/menu';
import Admin from './pages/admin/admin';

var packageJson = require('../package.json');

class App extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      user: {},
      isLog: false,
      adminRoute: "switches",
      homeRoute: "fav"
    };
  }
  UNSAFE_componentWillMount() {
    if (process.env.REACT_APP_IS_LOCALHOST !== undefined) {
      this.setState({ url: "http://localhost:3001" });
    } else {
      this.setState({
        url: `${process.env.REACT_APP_API_PROTO}://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`
      })
    }
    console.log(process.env);
  }

  componentDidMount() {
    const { history } = this.props;
    if (localStorage.getItem('userdata') !== " , " && localStorage.getItem('userdata') !== null) {
      fetch(`${this.state.url}/api/login`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          user: localStorage.getItem("userdata").split(",")[0],
          pass: localStorage.getItem("userdata").split(",")[1],
        }),
      }).then((res) => res.json()).then((user) => {
        if(user !== "no user") {
          this.setState({ user: user, isLog: true })
        } else {
          localStorage.setItem('userdata', " , ");
          history.push("/");
        }
      }).catch((err) => console.log(err));
    } else {
      localStorage.setItem('userdata', " , ");
      history.push("/");
    }
  }

  reload = (obj) => {
    this.componentDidMount();
    this.setState(obj)
  }

  resetRoutes = () => {
    this.setState({
      adminRoute: "switches",
      homeRoute: "fav"
    })
  }

  render() {
    const { user, url, isLog, adminRoute, homeRoute } = this.state;

    return <div className="App">
      {isLog
        ?<div>
          <Menu 
            user={user} 
            url={url} 
            reload={this.reload} 
            history={this.props.history}
            resetRoutes={this.resetRoutes}
          />
          <Switch>
            <Route exact path="/home" component={
              () => 
              <Home 
                url={url} 
                user={user} 
                reload={this.reload} 
                homeRoute={homeRoute}
              />
            } />
            <Route exact path="/admin" component={
              () =>
              <Admin 
                url={url} 
                user={user} 
                reload={this.reload} 
                adminRoute={adminRoute}
              />
            } />
          </Switch>
        </div>
        : null
      }
      <Switch>
        <Route exact path="/" component={
          () => <SignPage url={url} isLog={isLog}/>
        } />
      </Switch>
      <p className="version-text">{packageJson.version}</p>
    </div>;
  }
}

export default withRouter(App);
