import React, { Component } from 'react';
import EditFavProg from '../../components/editFavorite/editFavProg';
//import EditFavorite from '../../components/editFavorite/editFavorite';

class ProgCard extends Component {
  constructor(props) {
    super()
    this.state = {
      edit: false,
    }
  }

  editFav = () => {
    this.setState({edit: !this.state.edit});
  }

  createFav = () => {
    let favorites = JSON.parse(this.props.user.favorites);

    if (favorites.filter(e=>e.type==="prog").map(e => e.id).includes(this.props.prog.id)) {
      favorites = favorites.filter(item => item.id !== this.props.prog.id || item.type !== "prog").sort((a, b) => a.order - b.order);
      favorites = favorites.map((item, i) => ({ id: item.id, order: i + 1, type: item.type }))
    } else {
      favorites.push({ id: this.props.prog.id, order: favorites.length + 1 })
    }
    fetch(`${this.props.url}/api/setfav`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        favorites: favorites,
        id: this.props.user.id
      }),
    }).then((res) => res.json()).then((status) => {
      alert(!favorites.map(e => e.id).includes(this.props.prog.id) ? "Favorite was removes" : "Switch was added to favorites")
      this.props.reload({ homeRoute: this.props.route })
    }).catch((err) => console.log(err));
  }
    
  execute = () => {
    fetch(`${this.props.url}/api/getprogram`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD, id: this.props.prog.id }),
    }).then((res) => res.json()).then(({program, actions}) => {
      actions.forEach(action => {
        fetch(`${this.props.url}/api/toggleById`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: process.env.REACT_APP_API_PASSWORD,
            id: action.sw_id,
            val: action.ac_type
          })
        })
      });
      this.props.reload();
    }).catch((err) => console.log(err));
  }

  render() {
    const { prog, url, reload, user } = this.props;

    return (
      <div className="card" onDoubleClick={this.createFav}>
        <button className='menu_dotes' onClick={(e)=>{e.stopPropagation();this.editFav()}}/>
        <h6 onContextMenu={this.createFav} onDoubleClick={(e)=>{e.stopPropagation();this.createFav()}}>
          {prog.name.substring(0, 20)}
          {prog.name.length > 20 ? "..." : ""}
        </h6>
        <button 
          className="execute lamp"
          onClick={this.execute}
        />
        {this.state.edit
          ?<EditFavProg
              url={url}
              reload={reload}
              user={user}
              reset={this.editFav}
              prog={prog}
              route={this.props.route}
              areas={this.props.areas}
            />
          : null
        }
      </div>
    )
  }
}

export default ProgCard;
