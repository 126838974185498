import React, { Component } from 'react';

const days = [1,2,3,4,5,6,7];
var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}
const toTimeFormat = (date) => {
  console.log(date.toString());
  if(date.toString() === "Invalid Date") {
    const d = new Date();
    return twoDigits(d.getHours()) + ":" + twoDigits(d.getMinutes())
  }
  const d = new Date(date);
  if(window.navigator.language === "he-IL")
    d.setHours(d.getHours() - 2);
  return twoDigits(d.getHours()) + ":" + twoDigits(d.getMinutes());
};

const formatdate = (d) => {
  return (
    twoDigits(d.getFullYear()) +
    "-" +
    twoDigits(Number(d.getMonth() + 1)) +
    "-" +
    twoDigits(d.getDate())
  );
}

class EditScheduler extends Component {
  constructor(props) {
    super(props);
    const days = props.schedule.days === null ? "[]" : props.schedule.days;
    console.log(props.schedule.date);

    this.state = {
      start: toTimeFormat(new Date("2000-01-01T" + props.schedule.start)),
      end: toTimeFormat(new Date("2000-01-01T" + props.schedule.end)),
      days: props.schedule.daily ? "daily" : (props.schedule.date !== null ? "date" : "days"),
      date: formatdate(new Date(props.schedule.date === null ? new Date() : props.schedule.date)),
      Sun: JSON.parse(days).includes(1),
      Mon: JSON.parse(days).includes(2),
      Tue: JSON.parse(days).includes(3),
      Wed: JSON.parse(days).includes(4),
      Thu: JSON.parse(days).includes(5),
      Fri: JSON.parse(days).includes(6),
      Sat: JSON.parse(days).includes(7),
      type: props.schedule.start === null ? 0 : (props.schedule.end === null ? 1 : 2)
    };
  }
  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleChangeChk = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };
  
  handelSubmit = () => {
    let pickedDays = dayNames.map((d, i)=>{
      return (this.state[d] ? days[i] : false)
    })
    fetch(`${this.props.url}/api/editschedule`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        password: process.env.REACT_APP_API_PASSWORD, 
        start: (Number(this.state.type) === 0 ? "" : this.state.start),
        end: (Number(this.state.type) === 1 ? "" : this.state.end),
        switchId: this.props.switch.num_id,
        days: this.state.days==="date" 
          ? this.state.date 
          : (this.state.days==="daily" ? 1 : JSON.stringify(pickedDays.filter(d=>d))),
        dayType: this.state.days,
        id: this.props.schedule.id
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload();
      this.props.reset();
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h1 className='action_header'>Edit Scheduler:</h1><br/>
          <div className='time'>
            <h3>Mode</h3>
            <label>
              <select name='type' onChange={this.handelChange} className='popup_text select' style={{ marginLeft: "50px", width: "170px" }} value={this.state.type}>
                <option value="2">Both</option>
                <option value="1">On</option>
                <option value="0">Off</option>
              </select>
            </label>
          </div>
          <div className='time'>
            <h3>Start</h3>
            <input
              placeholder=" "
              maxLength="11"
              name="start"
              type="time"
              onChange={this.handelChange}
              style={{ marginLeft: "50px", width: "170px" }}
              value={this.state.start}
              disabled={Number(this.state.type) === 0}
            />
          </div>
          <br />
          <div className='time'>
            <h3>End</h3>
            <input
              placeholder=" "
              maxLength="11"
              name="end"
              type="time"
              onChange={this.handelChange}
              style={{ marginLeft: "60px", width: "170px" }}
              value={this.state.end}
              disabled={Number(this.state.type) === 1}
            />
          </div>
          <br />
          <div style={{display: "flex", justifyContent: "left", width: "80px"}}>
            <input
              maxLength="11"
              name="days"
              type="radio"
              value="daily"
              onChange={this.handelChange}
              checked={this.state.days==="daily"}
              id="daily"
            />
            <h3 htmlFor="daily">Daily</h3>
          </div>
          <div style={{ display: "flex", justifyContent: "left"}}>
            <div style={{ display: "flex", justifyContent: "left", width: "80px"}}>
              <input
                maxLength="11"
                name="days"
                type="radio"
                value="date"
                onChange={this.handelChange}
                checked={this.state.days==="date"}
                id="once"
              />
              <h3 htmlFor="once">Once</h3>
            </div>
            <input
              name="date"
              type="date"
              onChange={this.handelChange}
              style={{height: "20px", marginTop: "20px", marginLeft: "20px", width: "170px"}}
              value={this.state.date}
            />
          </div>
          <div style={{display: "flex", justifyContent: "left"}}>
            <div style={{ display: "flex", justifyContent: "left", width: "80px"}}>
              <input
                maxLength="11"
                name="days"
                type="radio"
                value="days"
                onChange={this.handelChange}
                checked={this.state.days==="days"}
                id="days"
              />
              <h3 htmlFor="days">Days</h3>
            </div>
            <div className='days' style={{ marginLeft: "20px" }}>
              {
                days.map((day, i)=>{
                  return (
                    <div key={i} style={{display: "flex"}}>
                      <input
                        type="checkbox"
                        onChange={this.handleChangeChk}
                        value={day}
                        name={dayNames[i]}
                        checked={this.state[dayNames[i]]}
                      />
                      <p>{dayNames[i]}</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} className="submit_button">Change</button>
            <button onClick={this.props.reset} className="cancel_button">Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditScheduler
