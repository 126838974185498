import React, { Component } from 'react';
import "./menu.css"
import AllOffWarning from './allOffWarning';

class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allOff: false
    }
  }
  open = (url) => {
    window.open(url, "_self");
    this.props.resetRoutes();
  }

  openHome = () => this.open("/home")
  openAdmin = () => this.open("/admin")
  openPro = () => this.open("/programs")
  resetAllOff = () => this.setState({ allOff: !this.state.allOff })

  render() {
    return (
      <div className='menu'>
        <h4 onClick={this.openHome}>Home</h4>
        <h4 onClick={this.openAdmin}>Admin</h4>
        <h4 onClick={this.openPro}>Programs</h4>
        <h4 onClick={this.resetAllOff}>All Off</h4>
        {this.state.allOff
          ? <AllOffWarning reset={this.resetAllOff} url={this.props.url} reload={this.props.reload} filter={true}/>
          : null
        }
      </div>
    )
  }
}

export default Menu

