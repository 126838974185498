import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./editFavorite.css";

class EditFavProg extends Component {
  constructor(props) {
    super();
    this.state = {
      order: JSON.parse(props.user.favorites).filter(e=>e.id===props.prog.id && e.type==="prog")[0].order,
      route: "none",
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  handelSubmit = () => {
    var newFavs = JSON.parse(this.props.user.favorites);
    const original = JSON.parse(this.props.user.favorites).filter(e => e.id === this.props.prog.id)[0];

    newFavs.find(e=>e.id===this.props.prog.id).order = this.state.order;
    if (this.state.order >= original.order) {
      newFavs = newFavs.map(e => {
        if (e.id !== original.id) {
          return { id: e.id, order: Number(e.order) >= this.state.order ? Number(e.order) - 1 : Number(e.order), type: e.type}
        } else {
          return { id: e.id, order: this.state.order, type: e.type };
        }
      });
    } else {
      newFavs = newFavs.map(e => {
        if(e.id !== original.id) {
          return { id: e.id, order: Number(e.order) >= this.state.order ? Number(e.order) + 1 : Number(e.order), type: e.type }
        } else {
          return { id: e.id, order: this.state.order, type: e.type };
        }
      });
    }
    fetch(`${this.props.url}/api/setfav`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        favorites: newFavs,
        id: this.props.user.id
      }),
    }).then((res) => res.json()).then((status) => {
      this.props.reload({ homeRoute: "fav" })
    }).catch((err) => console.log(err));
  }

  setRoute = (r) => this.setState({route: r})

  getMenu = () => {
    return<div 
      className='card_menu' 
      onClick={(e) => { e.stopPropagation(); }} 
      style={{ padding: "10px", width: "100px", zIndex: "999" }}
    >        
      <h1>Menu</h1>
      <button className='x_button' onClick={this.props.reset}>x</button>
      <div onClick={() => this.setRoute("editFav")} className='fav_menu_item'><h2>Edit Fav</h2></div>
      <div onClick={() => 0} className='fav_menu_item'><h2>Edit Program</h2></div>
      <div className='fav_menu_item'/>
    </div>
  }

  getEditFav = () => {
    return <div className='popup_out' style={{ zIndex: "999" }}>
      <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
        <h1>Edit Favorite</h1>
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='order'
            type="number"
            onChange={this.handelChange}
            value={this.state.order}
            min={1}
            max={JSON.parse(this.props.user.favorites).length}
          />
          <span>Order</span>
        </label>
        <div style={{ display: "flex" }}>
          <button style={{width: "50%"}} className='submit_button' onClick={this.handelSubmit}>Submit</button>
          <button style={{width: "50%"}} className='cancel_button' onClick={()=>this.setRoute("none")}>Cancel</button>
        </div>
      </div>
    </div>
  }

  render() {
    return (
      <div>
        {
          {
            'none': this.getMenu(),
            'editFav': this.getEditFav(),
          }[this.state.route]
        }
      </div>
    );
  }
}

export default withRouter(EditFavProg);
