import React, { Component } from 'react';

function ValidateIPaddress(ipaddress) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
    return (true)
  }
  return (false)
}

class EditSwitch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.switch.name,
      switchId: props.switch.id,
      ip: props.switch.ip_addr,
      second_ip: props.switch.second_ip,
      port: props.switch.port,
      second_port: props.switch.second_port,
      instance: props.switch.instance,
      second_instance: props.switch.second_instance,
      sw_type: props.switch.sw_type,
      order: props.switch.order,
      area: "",
      areas: [],
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  handelChangeArea = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, order: this.props.switches.filter(s => s.area_id === Number(value)).length + 1 });
    this.forceUpdate()
  }

  handelChangeNoSpaces = (event) => {
    const { name, value } = event.target;
    if (event.target.value[event.target.value.length - 1] !== ' ')
      this.setState({ [name]: value })
  }
  setSwitchesOrder = (con, plus) => {
    this.props.switches.filter(con).forEach(s => {
      fetch(`${this.props.url}/api/setswitchorder`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          id: s.num_id,
          order: plus(s),
        }),
      }).catch((err) => console.log(err));
    })
  }


  changeOrder = () => {

    if (this.state.area !== this.props.switch.area_id) {
      this.setSwitchesOrder(
        (s)=> s.order >= Number(this.state.order) && s.id !== this.props.switch.id && s.area_id !== this.props.switch.area_id,
        (s) => s.order + 1
      )
      this.setSwitchesOrder(
        (s) => s.order >= Number(this.state.order) && s.id !== this.props.switch.id && s.area_id === this.props.switch.area_id,
        (s) => s.order - 1
      )
    } else {
      if (this.state.order <= this.props.switch.order) {
        this.setSwitchesOrder(
          (s) => s.order >= Number(this.state.order) && s.id !== this.props.switch.id && s.area_id === this.props.switch.area_id,
          (s) => s.order + 1
        )
      } else {
        this.setSwitchesOrder(
          (s) => s.order <= Number(this.state.order) && s.id !== this.props.switch.id && s.area_id === this.props.switch.area_id,
          (s) => s.order - 1
        )
      }
    }
    fetch(`${this.props.url}/api/setswitchorder`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        id: this.props.switch.num_id,
        order: this.state.order,
      }),
    }).catch((err) => console.log(err));
  }

  handelSubmit = () => {
    this.changeOrder();
    let copy = this.state;
    delete copy.areas;
    if (!ValidateIPaddress(this.state.ip)) {
      alert("You have entered an invalid IP address!")
      this.componentDidMount();
      return;
    }
    fetch(`${this.props.url}/api/editswitch`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...copy,
        id: this.props.switch.num_id,
        password: process.env.REACT_APP_API_PASSWORD
      }),
    }).then((res) => res.json()).then((res) => {
      if (res === "id is invalid") {
        alert("You have entered an invalid ID");
        this.componentDidMount();
      } else {
        this.props.reload({ adminRoute: "switches" })
      }
    }).catch((err) => console.log(err));
  }
  componentDidMount() {
    const { areas } = this.props
    this.setState({ 
      areas: areas, 
      area: this.props.switch.area_id !== 0 
        ? areas.filter(a => a.id === this.props.switch.area_id)[0].id 
        : areas[0].id
    })
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px", lineHeight: "1px" }}>
          <h1 className='action_header'>Edit Switch:</h1>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='50'
              name='name'
              type="text"
              onChange={this.handelChange}
              value={this.state.name}
            />
            <span>Switch Name</span>
          </label>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='50'
              name='switchId'
              type="text"
              onChange={this.handelChangeNoSpaces}
              value={this.state.switchId}
            />
            <span>Switch ID</span>
          </label>
          <div className={Number(this.state.sw_type)===4 ? "fanWrap" : ''}>
            {Number(this.state.sw_type)===4
              ?<h3 style={{margin:"15px"}}>SWITCH PROPERTIES:</h3>
              : null
            }
            <label>
              <input
                className='popup_text'
                placeholder=" "
                maxLength='15'
                name='ip'
                type="text"
                onChange={this.handelChangeNoSpaces}
                value={this.state.ip}
              />
              <span>IP Adress</span>
            </label><br />
            <label>
              <input
                className='popup_text'
                placeholder=" "
                maxLength='11'
                name='port'
                type="number"
                onChange={this.handelChangeNoSpaces}
                value={this.state.port}
              />
              <span>Port</span>
            </label><br />
            <label>
              <select name='instance' onChange={this.handelChange} className='popup_text' value={this.state.instance}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </label><br/>
          </div>
          {Number(this.state.sw_type)===4 
            ?<div className="fanWrap">
              <h3 style={{margin: "15px"}}>FAN PROPERTIES</h3>
              <label>
                <input
                  className='popup_text'
                  placeholder=" "
                  maxLength='15'
                  name='second_ip'
                  type="text"
                  onChange={this.handelChangeNoSpaces}
                  value={this.state.second_ip}
                />
                <span>Fan IP Adress</span>
              </label>
              <label>
                <input
                  className='popup_text'
                  placeholder=" "
                  maxLength='11'
                  name='second_port'
                  type="number"
                  onChange={this.handelChangeNoSpaces}
                  value={this.state.second_port}
                />
                <span>Fan Port</span>
              </label>
              <label>
                <select value={this.state.second_instance} name='second_instance' onChange={this.handelChange} className='popup_text select'style={{width: "250px"}}>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </label>
            </div>
            : null
          }
          <label>
            <select name='sw_type' onChange={this.handelChange} className='popup_text' value={this.state.sw_type}>
              <option value="0">room</option>
              <option value="1">door</option>
              <option value="2">boiler</option>
              <option value="3">cabin</option>
              <option value="4">fan</option>
            </select>
          </label><br />
          <label>
            <select name='area' onChange={this.handelChangeArea} className='popup_text' value={this.state.area}>
              {
                this.state.areas.map(area => {
                  return <option key={area.id} value={area.id}>{area.name}</option>
                })
              }
            </select>
          </label><br/>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='255'
              name='order'
              type="number"
              onChange={this.handelChange}
              value={this.state.order}
              min={1}
              max={this.props.switches.filter(a=>a.area_id===Number(this.state.area)).length+1}
            />
            <span>Order</span>
          </label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} className='submit_button'>Change</button>
            <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditSwitch;