import React, { Component } from 'react'

class EditArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.area.name,
      order: props.area.order
    }
  }
  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  handelSubmit = () => {
    if(this.state.order > this.props.area.order) {
      this.props.areas.filter(a=>a.order <= Number(this.state.order)&&a.id!==this.props.area.id).forEach(area=>{
        fetch(`${this.props.url}/api/editarea`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: process.env.REACT_APP_API_PASSWORD,
            id: area.id,
            order: area.order - 1,
            name: area.name
          }),
        }).catch((err) => console.log(err));
      })
    }
    if (this.state.order < this.props.area.order) {
      this.props.areas.filter(a => a.order >= Number(this.state.order) && a.id !== this.props.area.id).forEach(area => {
        fetch(`${this.props.url}/api/editarea`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: process.env.REACT_APP_API_PASSWORD,
            id: area.id,
            order: area.order + 1,
            name: area.name
          }),
        }).catch((err) => console.log(err));
      })
    }

    fetch(`${this.props.url}/api/editarea`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        name: this.state.name,
        id: this.props.area.id,
        order: Number(this.state.order)
      }),
    }).then((res) => res.json()).then(() => {
      this.props.reload({ adminRoute: "areas" });
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h1 className='action_header'>Edit Area:</h1>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='255'
              name='name'
              type="text"
              onChange={this.handelChange}
              value={this.state.name}
            />
            <span>Area Name</span>
          </label>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='255'
              name='order'
              type="number"
              onChange={this.handelChange}
              value={this.state.order}
              min={1}
              max={this.props.areas.length + 1}
            />
            <span>Order</span>
          </label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} className='submit_button'>Change</button>
            <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditArea;
