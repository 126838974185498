// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./icons/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".App {\n  text-align: center;\n  -webkit-user-select: none;\n          user-select: none;\n  -webkit-touch-callout: none !important;\n}\n\nhtml {\n  position: absolute;\n  height: 95%;\n  width: 100%;\n  bottom: 0%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  background-attachment:fixed;   \n}\n\n.content {\n  margin-top: 5px;\n}\n\n.version-text {\n  position: fixed;\n  bottom: 0.5%;\n  left: 1%;\n  font-weight: bold;\n  font-size: 2.5vh;\n  color: white;\n  z-index: 101;\n}\n", ""]);
// Exports
module.exports = exports;
