import React, { Component } from 'react'
import DeleteArea from "./deleteArea.js";
import EditArea from './editArea.js';

class AreasRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      areas: [],
      editArea: false,
      deleteArea: false,
      selected: {}
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getareas`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((areas) => {
      if (this._isMounted) {
        this.setState({ areas: areas })
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { url, reload, setRoute } = this.props;

    return (
      <div className='table'>
        <button
          className='add'
          style={{ margin: "15px", position: "absolute", left: "10px" }}
          onClick={() => setRoute("addArea")}
        />
        <br />
        <table id="t01" align="center" style={{ width: "80%", marginTop: "40px" }}>
          <thead>
            <tr>
              <th style={{ width: "60%" }}>Area Name</th>
              <th style={{ width: "20%" }}>Edit</th>
              <th style={{ width: "20%" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.areas.map(area => {
                return <tr key={area.id}>
                  <td style={{ width: "60%" }}>
                    {area.name}
                  </td>
                  <td style={{ width: "20%" }}>
                    <button 
                      className='edit' 
                      onClick={() => this.setState({ editArea: true, selected: area })}
                    />
                  </td>
                  <td style={{ width: "20%" }}>
                    <button 
                      className='delete' 
                      onClick={() => this.setState({ deleteArea: true, selected: area })}
                    />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
        {this.state.deleteArea
          ? <DeleteArea
            area={this.state.selected}
            reload={reload}
            url={url}
            reset={() => this.setState({ deleteArea: false })}
            areas={this.state.areas}
          />
          : null
        }
        {this.state.editArea
          ? <EditArea
            area={this.state.selected}
            reload={reload}
            url={url}
            reset={() => this.setState({ editArea: false })}
            areas={this.state.areas}
          />
          : null
        }
      </div>
    )
  }
}

export default AreasRoute
