import React, { Component } from 'react';

function ValidateIPaddress(ipaddress) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
    return (true)
  }
  return (false)
}

class AddSwitch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      switchId: "",
      ip: "",
      second_ip: "",
      port: "80",
      second_port: "80",
      instance: "0",
      second_instance: "0",
      sw_type: "0",
      area: "",
      order: 1,
      areas: [],
      switches: [],
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  handelChangeArea = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, order: this.state.switches.filter(s => s.area_id === Number(value)).length + 1 });
    this.forceUpdate()
  }


  handelChangeNoSpaces = (event) => {
    const { name, value } = event.target;
    if (event.target.value[event.target.value.length - 1] !== ' ')
      this.setState({ [name]: value })
  }
  setSwitchesOrder = (con, plus) => {
    this.state.switches.filter(con).forEach(s => {
      fetch(`${this.props.url}/api/setswitchorder`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          id: s.num_id,
          order: plus(s),
        }),
      }).catch((err) => console.log(err));
    })
  }

  handelSubmit = () => {
    let copy = this.state;

    if(!ValidateIPaddress(this.state.ip)) {
      alert("You have entered an invalid IP address!")
      this.componentDidMount();
      return;
    }

    this.setSwitchesOrder(
      (s) => s.order >= Number(this.state.order) && s.area_id === Number(this.state.area),
      (s) => s.order + 1
    )

    fetch(`${this.props.url}/api/addswitch`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...copy,
        password: process.env.REACT_APP_API_PASSWORD 
      }),
    }).then((res) => res.json()).then((res) => {
      if (res === "id is invalid") {
        alert("You have entered an invalid ID");
        this.componentDidMount();
      } else {
        this.props.reload({adminRoute: "switches"})
      }
    }).catch((err) => console.log(err));
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getareas`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((areas) => {
      if (this._isMounted) {
        fetch(`${this.props.url}/api/getswitches`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
        }).then((res) => res.json()).then((switches) => {
          if (this._isMounted) {
            var newAreas = areas.sort((a, b) => a.order - b.order);
            this.setState({
              areas: newAreas, 
              area: newAreas[0].id,
              switches: switches,
              order: switches.filter(s => s.area_id === Number(newAreas[0].id)).length + 1
            })
          }
        }).catch((err) => console.log(err));
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    console.log(this.state)
    return (
      <div className='action_container'>
        <br/>
        <h1 className='action_header'>Add Switch:</h1>
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='50'
            name='name'
            type="text"
            onChange={this.handelChange}
          />
          <span>Switch Name</span>
        </label><br />
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='50'
            name='switchId'
            type="text"
            onChange={this.handelChangeNoSpaces}
            value={this.state.switchId}
          />
          <span>Switch ID</span>
        </label><br />
        <div className={this.state.sw_type==="4" ? "fanWrap" : ''}>
          {this.state.sw_type==="4"
            ?<h3 style={{margin:"15px"}}>SWITCH PROPERTIES:</h3>
            : null
          }
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='15'
              name='ip'
              type="text"
              onChange={this.handelChangeNoSpaces}
              value={this.state.ip}
            />
            <span>IP Adress</span>
          </label><br />
          <label>
            <input
              className='popup_text'
              placeholder=" "
              maxLength='11'
              name='port'
              type="number"
              onChange={this.handelChangeNoSpaces}
              value={this.state.port}
            />
            <span>Port</span>
          </label><br />
          <label>
            <select name='instance' onChange={this.handelChange} className='popup_text select'>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </label>
        </div>
        {this.state.sw_type==="4"
          ?<div className="fanWrap">
            <h3 style={{margin: "15px"}}>FAN PROPERTIES</h3>
            <label>
              <input
                className='popup_text'
                placeholder=" "
                maxLength='15'
                name='second_ip'
                type="text"
                onChange={this.handelChangeNoSpaces}
                value={this.state.second_ip}
              />
              <span>Fan IP Adress</span>
            </label>
            <label>
              <input
                className='popup_text'
                placeholder=" "
                maxLength='11'
                name='second_port'
                type="number"
                onChange={this.handelChangeNoSpaces}
                value={this.state.second_port}
              />
              <span>Fan Port</span>
            </label>
            <label><br/>
              <select name='second_instance' onChange={this.handelChange} className='popup_text select'>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </label>
          </div>
          : null
        }
        <br/><label>
          <select name='sw_type' onChange={this.handelChange} className='popup_text select'>
            <option value="0">room</option>
            <option value="1">door</option>
            <option value="2">boiler</option>
            <option value="3">cabin</option>
            <option value="4">fan</option>
          </select>
        </label>
        <label>
          <select name='area' onChange={this.handelChangeArea} className='popup_text select'>
            {
              this.state.areas.map(area=>{
              return <option key={area.id} value={area.id}>{area.name}</option>
              })
            }
          </select>
        </label>
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='order'
            type="number"
            onChange={this.handelChange}
            value={this.state.order}
            min={1}
            max={this.state.switches.filter(a => a.area_id === Number(this.state.area)).length + 1}
          />
          <span>Order</span>
        </label>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={this.handelSubmit} className='submit_button'>Add</button>
          <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
        </div>
      </div>
    )
  }
}

export default AddSwitch;