import React, { Component } from 'react';
import "./admin.css";
import UsersRoute from './users/usersRoute';
import AddUser from './users/addUser';
import AreasRoute from './areas/areasRoute';
import AddArea from './areas/addArea';
import SwitchesRoute from "./switches/switchesRoute";
import AddSwitch from './switches/addSwitch';
import VersionReport from './switches/versionReport';
import Scheduler from './scheduler/scheduler';
import AddScheduler from './scheduler/addScheduler';
import SetAllOff from './setAllOff';


class Admin extends Component {
  constructor(props) {
    super()
    this.state = {
      route: props.adminRoute,
      selectedSwitch: {},
      temp: ""
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/gettemp`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((temp) => {
      if (this._isMounted) {
        this.setState({ temp: temp })
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  setRoute = (e) => this.setState({ route: e.target.textContent.toLowerCase()});
  setRouteText = (s, selected=this.state.selectedSwitch) => {
    this.setState({ route: s, selectedSwitch: selected});
  }
  logOut = () => {localStorage.setItem("userdata", " , ");window.open("/", "_self")}
  openAlexa = () => {
    const client_id = "amzn1.application-oa2-client.b4ae9eff5543462090e0b31a9f82530d";
    window.open(`https://alexa.amazon.com/spa/skill-account-linking-consent?fragment=skill-account-linking-consent&client_id=${client_id}&scope=alexa::skills:account_linking&skill_stage=development&response_type=code&redirect_uri=https://elieyal.dynu.com/alexa_app/&state=LinkingAuthCode`, `_self`)
  }

  render() {
    const { user, url, reload } = this.props;
    const temp = Math.round(Number(this.state.temp.split(" ")[1])/1000 * 10) / 10;

    return (
      <div className='page admin'>
        {this.state.route !== "all off"
          ? <div>
            <button className='reload' onClick={()=>this.props.reload({adminRoute: this.state.route})}/>
            <p className='temp' style={{color: temp < 70 ? "green" : "red", marginTop: "25px"}}>
              {this.state.temp.split(" ")[0]} {temp} C°
            </p>
          </div>
          : null
        }
        <div>
          {user.is_root
            ? <div className='side_menu'>
                <h4 style={{ color: "lightblue" }}>Admin</h4>
                <h4 onClick={this.setRoute} style={{ color: this.state.route === "switches" ? "#3385ff" : "white" }}>Switches</h4>
                <h4 onClick={this.setRoute} style={{ color: this.state.route === "areas" ? "#3385ff" : "white" }}>Areas</h4>
                <h4 onClick={this.setRoute} style={{ color: this.state.route === "users" ? "#3385ff" : "white" }}>Users</h4>
                <h4 onClick={this.setRoute} style={{ color: this.state.route === "all_off" ? "#3385ff" : "white" }}>All Off</h4>
                <h4 onClick={this.openAlexa} style={{ color: "white" }}>Connect Alexa</h4>
                <hr /> 
                {
                  {
                    'users': <h4 onClick={() => this.setRouteText("addUser")}>Add</h4>,
                    'addUser': <h4 onClick={() => this.setRouteText("users")}>Back</h4>,
                    'areas': <h4 onClick={() => this.setRouteText("addArea")}>Add</h4>,
                    'addArea': <h4 onClick={() => this.setRouteText("areas")}>Back</h4>,
                    'switches': <h4 onClick={() => this.setRouteText("addSwitch")}>Add</h4>,
                    'addSwitch': <h4 onClick={() => this.setRouteText("switches")}>Back</h4>,
                    'scheduler': <h4 onClick={() => this.setRouteText("addScheduler")}>Add</h4>,
                    'addScheduler': <h4 onClick={() => this.setRouteText("scheduler")}>Back</h4>,
                  }[this.state.route]
                }
                {
                  {
                    'scheduler': <h4 onClick={() => this.setRouteText("switches")}>Back</h4>,
                  }[this.state.route]
                }
                <h4 onClick={this.logOut}>Log Out</h4>
              </div>
            : <div className='side_menu'>
                <h4 style={{ color: "lightblue" }}>Admin</h4>
                <h4 onClick={this.setRoute} style={{ color: this.state.route === "users" ? "#3385ff" : "white" }}>Users</h4>
              </div> 
          }
        </div>
        <div className='content'>
          {
            {
              'none': <p>{user.username}, you are in route none</p>,
              'users': <UsersRoute url={url} setRoute={this.setRouteText} reload={reload} user={user}/>,
              'addUser': <AddUser url={url} reset={() => this.setRouteText("users")} reload={reload}/>,
              'areas': <AreasRoute url={url} setRoute={this.setRouteText} reload={reload} user={user}/>,
              'addArea': <AddArea url={url} reset={() => this.setRouteText("areas")} reload={reload} />,
              'switches': <SwitchesRoute url={url} setRoute={this.setRouteText} reload={reload} user={user}/>,
              'addSwitch': <AddSwitch url={url} reset={() => this.setRouteText("switches")} reload={reload}/>,
              'versionReport': <VersionReport url={url} setRoute={this.setRouteText} reload={reload} />,
              'scheduler': <Scheduler 
                              reload={reload} 
                              url={url} 
                              reset={() => this.setRouteText("switches")}
                              setRoute={this.setRouteText}
                            />,
              'addScheduler': <AddScheduler
                                reload={() => this.setRouteText("scheduler")}
                                url={this.props.url}
                                reset={() => this.setRouteText("scheduler")}
                              />,
              'all off': <SetAllOff url={url} setRoute={this.setRouteText} reload={reload} user={user}/>
            }[this.state.route]
          }
        </div>
      </div>
    )
  }
}

export default Admin
