import React, { Component } from 'react';
import './timer.css';

function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}

const toMysqlFormatTime = (d) => {
  return twoDigits(d.getHours()) + ":" + twoDigits(d.getMinutes()) + ":00";
};

const toMysqlFormat = (d) => {
  return d.getFullYear() + "-" + twoDigits(1 + d.getMonth()) + "-" + twoDigits(d.getDate());
};


class Timer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hours: 0,
      minutes: 30
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  handelSubmit = () => {
    var endTime = new Date();
    endTime.setHours(endTime.getHours() + Number(this.state.hours));
    endTime.setMinutes(endTime.getMinutes() + Number(this.state.minutes));
    endTime.setSeconds(new Date().getSeconds());
    this.props.reset();
    
    if (Number(this.state.hours) === 0 && Number(this.state.minutes) ===  0) {
      alert("invalid hours or minutes!!!")
    } else {
      if (this.state.hours!=="" && this.state.minutes!=="") {
        fetch(`${this.props.url}/api/addtimer`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: process.env.REACT_APP_API_PASSWORD,
            endTime: toMysqlFormatTime(endTime),
            enddate: toMysqlFormat(endTime),
            switchId: this.props.switchItem.num_id,
          }),
        }).then(res=>res.json()).then(()=>{
          fetch(`${this.props.url}/api/toggle`, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              password: process.env.REACT_APP_API_PASSWORD,
              val: 1,
              ip_addr: this.props.switchItem.ip_addr,
              gpio: this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio",
              port: this.props.switchItem.port,
            }),
          }).then((res) => res.json()).then((status) => {
            this.props.reload({ homeRoute: this.props.route })
          }).catch((err) => console.log(err));
        }).catch((err) => console.log(err));
      } else {
        alert("invalid hours or minutes!!!")
      }
    }
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h1>Set Timer</h1>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              name='hours'
              type="number"
              onChange={this.handelChange}
              value={this.state.hours}
              min={0}
              max={12}
            />
            <span>Hours</span>
          </label><br/>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              name='minutes'
              type="number"
              onChange={this.handelChange}
              value={this.state.minutes}
              min={0}
              max={59}
            />
            <span>Minutes</span>
          </label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} style={{width: "50%"}} className='submit_button'>Add</button>
            <button onClick={this.props.reset} style={{width: "50%"}} className='cancel_button'>Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default Timer;