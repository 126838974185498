import React, { Component } from 'react';
function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}
const toMysqlFormat = (date) => {
  return date.getFullYear() + "-" + twoDigits(1 + date.getMonth()) + "-" + twoDigits(date.getDate()) + " " + twoDigits(date.getHours()) + ":" + twoDigits(date.getMinutes()) + ":" + twoDigits(0);
}


class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",
      passwordTwo: "",
      isAdmin: false,
      users: []
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }
  checkChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  componentDidMount() {
    fetch(`${this.props.url}/api/getusers`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((users) => {
      this.setState({ users: users })
    }).catch((err) => console.log(err));
  }

  handelSubmit = () => {
    if(this.state.password === this.state.passwordTwo && this.state.users.filter(u=>u.username===this.state.username).length < 1) {
      fetch(`${this.props.url}/api/adduser`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: this.state.username, 
          pass: this.state.password, 
          date: toMysqlFormat(new Date()),
          isAdmin: this.state.isAdmin ? 1 : 0,
          password: process.env.REACT_APP_API_PASSWORD,
        }),
      }).then((res) => res.json()).then((users) => {
        this.props.reload({adminRoute: "users"});
      }).catch((err) => console.log(err));
    } else {
      alert('Errror creating user...')
    }
  }

  render() {
    return (
      <div className='action_container'>
        <br/>
        <h1 className='action_header'>Add User:</h1>
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='username'
            type="text"
            onChange={this.handelChange}
          />
          <span>User Name</span>
        </label><br />
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='password'
            type="password"
            onChange={this.handelChange}
          />
          <span>Password</span>
        </label><br />
        <label>
          <input
            className='popup_text'
            placeholder=" "
            maxLength='255'
            name='passwordTwo'
            type="password"
            onChange={this.handelChange}
          />
          <span>Repet Password</span>
        </label><br />
        <div style={{display: "flex", justifyContent: "center"}}>
          <h4>Admin User</h4>
          <div style={{ marginTop: "20px", marginLeft: "5px" }}>
            <input type="checkbox" name="isAdmin" onChange={this.checkChange}/>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={this.handelSubmit} className='submit_button'>Add</button>
          <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
        </div>
      </div>
    )
  }
}

export default AddUser;
