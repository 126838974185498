import React, { Component } from 'react'
import EditScheduler from "./editScheduler";
import DeleteScheduler from './deleteScheduler';

const days = [1, 2, 3, 4, 5, 6, 7];
var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}
const formatdate = (date) => {
  const d = new Date(new Date().toDateString() + " " + date);
  return twoDigits(d.getHours()) + ":" + twoDigits(d.getMinutes());
}

const formatdate1 = (d) => {
  return twoDigits(d.getDate()) + "/" + twoDigits(Number(d.getMonth()+1)) + "/" + d.getFullYear();
}

class Scheduler extends Component {
  constructor(props) {
    super()
    this.state = {
      schedules: [],
      edit: false,
      delete: false,
      selected: {},
      switch: {}
    }
  }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getschedules`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        password: process.env.REACT_APP_API_PASSWORD, 
        id: localStorage.getItem("sc_sw") 
      }),
    }).then((res) => res.json()).then((schedules) => {
      fetch(`${this.props.url}/api/getswitchbyid`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD, 
          id: localStorage.getItem("sc_sw") 
        }),
      }).then((res) => res.json()).then((sw) => {
        if (this._isMounted) {
          this.setState({ schedules: schedules, switch: sw });
        }
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  handleChangeChk = (event) => {
    const { checked } = event.target;
    console.log("object")
    fetch(`${this.props.url}/api/chnageenablesc`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD, 
        id: this.state.switch.num_id,
        val: checked
      }),
    }).then((res) => res.json()).then(() => {
      this.componentDidMount();
    }).catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="scheduler content" style={{ background: "none" }}>
        <button
          className="add"
          style={{ margin: "15px", position: "absolute", left: "10px" }}
          onClick={() => this.props.setRoute("addScheduler")}
        />
        <button
          className="close"
          style={{ margin: "15px", position: "absolute", left: "50px" }}
          onClick={this.props.reset}
        />
        <div style={{marginTop: "50px"}}>
          <h1 className="action_header">Scheduler: {this.state.switch.name}</h1>
          <div style={{display: "flex", justifyContent: "center"}}>
            <h3 className="action_header second_header">
              Enable Scheduler:
            </h3>
            <input
              onChange={this.handleChangeChk}
              type="checkbox"
              style={{ height: "20px", width: "20px"}}
              checked={this.state.switch.sched_set || false}
            />
          </div>
          <table
            id="t01"
            align="center"
            style={{ width: "100%", marginTop: "40px" }}
          >
            <thead>
              <tr>
                <th style={{ width: "30%" }}>On</th>
                <th style={{ width: "30%" }}>Off</th>
                <th style={{ width: "60%" }}>Days</th>
                <th style={{ width: "20%" }}></th>
                <th style={{ width: "20%" }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.schedules.map((schedule) => {
                return (
                  <tr key={schedule.id}>
                    <td style={{ width: "60%" }}>{schedule.start !== null ? formatdate(schedule.start) : ''}</td>
                    <td style={{ width: "60%" }}>{schedule.end !== null ? formatdate(schedule.end) : ''}</td>
                    <td style={{ width: "20%" }}>
                      {schedule.daily
                        ? "daily"
                        : schedule.date !== null
                        ? formatdate1(new Date(schedule.date))
                        : JSON.parse(schedule.days).map(
                            (d) => dayNames[days.indexOf(d)] + " "
                          )}
                    </td>
                    <td style={{ width: "20%" }}>
                      <button
                        className="edit"
                        onClick={() =>
                          this.setState({
                            edit: true,
                            selected: schedule,
                          })
                        }
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <button
                        className="delete"
                        onClick={() =>
                          this.setState({
                            delete: true,
                            selected: schedule,
                          })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {this.state.edit ? (
          <EditScheduler
            schedule={this.state.selected}
            switch={this.state.switch}
            reload={() => this.componentDidMount()}
            url={this.props.url}
            reset={() => this.setState({ edit: false })}
          />
        ) : null}
        {this.state.delete ? (
          <DeleteScheduler
            schedule={this.state.selected}
            reload={() => this.componentDidMount()}
            url={this.props.url}
            reset={() => this.setState({ delete: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default Scheduler
