import React, { Component } from 'react';
import './timer.css';
const formatdate = (date) => {
  return new Date(date)
    .toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
}

function twoDigits(d) {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
}

const toMysqlFormatTime = (d) => {
  return twoDigits(d.getHours()) + ":" + twoDigits(d.getMinutes()) + ":00";
};

function diff(start, end) {
  start = start.split(":");
  end = end.split(":");
  var startDate = new Date(0, 0, 0, start[0], start[1], 0);
  var endDate = new Date(0, 0, 0, end[0], end[1], 0);
  var diff = endDate.getTime() - startDate.getTime();
  var hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  var minutes = Math.floor(diff / 1000 / 60);

  if (hours < 0)
    hours = hours + 24;

  return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
}

const checkTimer = (s) => {
  return diff(formatdate(new Date()), formatdate(new Date(new Date().toLocaleDateString() + " " + s.end)))
}


class EditTimer extends Component {
  constructor(props) {
    super(props);
    const timer = checkTimer(this.props.timer);

    this.state = {
      hours: Number(timer.split(":")[0]),
      minutes: Number(timer.split(":")[1])
    }
  }

  handelChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  handelSubmit = () => {
    var endTime = new Date(new Date().getTime() + (this.state.minutes * 60000) + (this.state.hours * 60 * 60 * 1000));
    if (Number(this.state.hours) === 0 && Number(this.state.minutes) === 0) {
      alert("invalid hours or minutes!!!")
    } else {
      if (this.state.hours !== "" && this.state.minutes !== "") {
        fetch(`${this.props.url}/api/edittimer`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: process.env.REACT_APP_API_PASSWORD,
            endTime: toMysqlFormatTime(endTime),
            id: this.props.timer.id,
          }),
        }).then(res => res.json()).then(() => {
          this.props.reload({homeRoute: this.props.route})
        }).catch((err) => console.log(err));
      } else {
        alert("invalid hours or minutes!!!")
      }
    }
  }

  stopTimer = () => {
    this.props.reset();
    fetch(`${this.props.url}/api/deleteschedule`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: process.env.REACT_APP_API_PASSWORD,
        id: this.props.timer.id
      }),
    }).then((res) => res.json()).then(() => {
      fetch(`${this.props.url}/api/toggle`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: process.env.REACT_APP_API_PASSWORD,
          val: 0,
          ip_addr: this.props.switchItem.ip_addr,
          gpio: this.props.switchItem.instance ? "gpio" + this.props.switchItem.instance : "gpio",
          port: this.props.switchItem.port,
        }),
      }).then((res) => res.json()).then((status) => {
        this.props.reload({ homeRoute: this.props.route })
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div className='popup_out' style={{ zIndex: "999" }}>
        <div className='popup' onClick={(e) => { e.stopPropagation(); }} style={{ padding: "30px" }}>
          <h1>Reset Timer</h1>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              name='hours'
              type="number"
              onChange={this.handelChange}
              value={this.state.hours}
              min={0}
              max={12}
            />
            <span>Hours</span>
          </label>
          <label>
            <input
              className='popup_text'
              placeholder=" "
              name='minutes'
              type="number"
              onChange={this.handelChange}
              value={this.state.minutes}
              min={0}
              max={59}
            />
            <span>Minutes</span>
          </label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.handelSubmit} className='submit_button'>Change</button>
            <button onClick={this.props.reset} className='cancel_button'>Cancel</button>
            <button onClick={this.stopTimer} className='cancel_button'>Stop</button>
          </div>
        </div>
      </div>
    )
  }
}

export default EditTimer;