import React, { Component } from 'react'
import UserDelete from './userDelete';
import EditUser from './editUser';
import AddSwitchesToUser from './addSwitchesToUser';

class UsersRoute extends Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      deleteUser: false,
      editUser: false,
      addSwitches: false,
      selected: {}
    }
  }
  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getusers`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((users) => {
      if (this._isMounted) {
        this.setState({ users: users })
      }
    }).catch((err) => console.log(err));
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { url, reload, setRoute, user } = this.props;

    return (
      <div className='table'>
        {user.is_root
          ? <button
            className='add'
            style={{ margin: "15px", position: "absolute", left: "10px" }}
            onClick={() => setRoute("addUser")}
          />
          : null
        }<br />
        <table id="t01" align="center" style={{width: "80%", marginTop: "40px"}}>
          <thead>
            <tr>
              <th style={{ width: "60%" }}>User Name</th>
              <th style={{ width: "20%" }}>Edit</th>
              <th style={{ width: "20%" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.users.filter(u => user.is_root || u.id === user.id).map(userRow=>{
                return <tr key={userRow.id}>
                  <td style={{ width: "60%" }}>
                    {userRow.username}
                  </td>
                  <td style={{ width: "20%" }}>
                    <button 
                      className='edit' 
                      onClick={() => {
                        this.setState({ editUser: true, selected: userRow })
                      }}
                    />
                  </td>
                  <td style={{ width: "20%" }}>
                    <button 
                      className='delete'
                      onClick={() => this.setState({ deleteUser: true, selected: userRow })}
                    />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
        {this.state.deleteUser && user.is_root
          ? <UserDelete 
              user={this.state.selected} 
              reload={reload} 
              url={url} 
              reset={()=>this.setState({deleteUser: false})}
            />
          : null
        }
        {this.state.editUser
          ? <EditUser 
              user={this.state.selected} 
              reload={reload} 
              url={url} 
              reset={() => this.setState({ editUser: false })}
              addSwitches={() => this.setState({ addSwitches: true, editUser: false })}
              users={this.state.users}
              myUser={user}
            />
          : null
        }
        {this.state.addSwitches
          ? <AddSwitchesToUser
              user={this.state.selected}
              reload={reload}
              url={url}
              reset={() => this.setState({ addSwitches: false })}
              users={this.state.users}
              myUser={user}
            />
          : null
        }
      </div>
    )
  }
}

export default UsersRoute;
