import React, { Component } from 'react';
import "./home.css";
import SwitchesList from './switchesList';
import Card from './card';
import ProgCard from './progCard';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

class Home extends Component {
  constructor(props) {
    super();
    this.state = { 
      route: props.homeRoute || "",
      areas: [],
      switches: [],
      programs: [],
      popup: false,
      onPage: true
     }
  }

  setRoute = (e) => this.setState({ route: e.target.textContent.toLowerCase() });
  setRouteText = (s) => this.setState({ route: s });
  logOut = () => { localStorage.setItem("userdata", " , "); window.open("/", "_self") }

  componentDidMount() {
    this._isMounted = true;
    fetch(`${this.props.url}/api/getareas`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
    }).then((res) => res.json()).then((areas) => {
      fetch(`${this.props.url}/api/getswitches`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
      }).then((res) => res.json()).then((switches) => {
        fetch(`${this.props.url}/api/getprograms`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD }),
        }).then((res) => res.json()).then((progs) => {
          if (this._isMounted) {
            this.setState({ areas: areas, switches: switches, programs: progs })
          }
        }).catch((err) => console.log(err));
      }).catch((err) => console.log(err));
    }).catch((err) => console.log(err));
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleVisibilityChange = () => {
    if (document[hidden]) {
      this.setState({ onPage: false });
    } else {
      this.setState({ onPage: true });
    }
  }
  openPop = () => {
    this.setState({popup: true});
  }
  closePop = () => {
    this.setState({ popup: false });
  }

  render() {
    const { user, url, reload } = this.props;
    let SwitchesAreas = this.state.areas.map(a=>a.id);
    const favorites = this.state.switches.filter(s => 
      JSON.parse(user.favorites).filter(e=> e.type === "switch").map(e => e.id).includes(s.num_id) 
    ).map(s => 
      ({ ...s, order: JSON.parse(user.favorites).filter(e => e.id === s.num_id)[0].order, type: "switch"})
    ).concat(
      this.state.programs.filter(p => 
        JSON.parse(user.favorites).filter(e=> e.type === "prog").map(e => e.id).includes(p.id) 
      ).map(p=>({ ...p, order: JSON.parse(user.favorites).filter(e => e.id === p.id)[0].order, type: "prog"}))
    ).sort((a,b)=> a.order-b.order)

    if(!user.is_root) {
      if (user.visible_sw !== null) {
        SwitchesAreas = this.state.switches.filter(s => JSON.parse(user.visible_sw).includes(s.num_id)
        ).map(s => s.area_id);
      } else {
        SwitchesAreas = [];
      }
    }
    
    return ( 
      <div className='page home'>
        <button 
          className='reload' 
          onClick={()=>this.props.reload({homeRoute: this.state.route})} 
          style={{ top: this.state.route === "fav" ? "1%" : "40px", zIndex: "101"}}
        />
        <div className='side_menu'>
          <h4 style={{ color: "lightblue" }}>Home</h4>
          <h4 onClick={this.setRoute} style={{ color: this.state.route === "fav" ?"#3385ff":"white"}}>Fav</h4>
          {
            this.state.areas.filter(a=>SwitchesAreas.includes(a.id)).sort((a,b)=>a.order - b.order).map(area=>{
              return <h4 
                onClick={()=>this.setRouteText("a_"+area.id.toString())} 
                key={area.id} 
                style={{ color: this.state.route === "a_" + area.id.toString() ? "#3385ff" : "white"}}
              >
                {area.name}
              </h4>
            })
          }
          <hr />
          <h4 onClick={this.logOut}>Log Out</h4>
        </div>
        <div className='content'>
          {this.state.route==="fav" && user.favorites !== null
            ?<div className='favorites'>
              {
                favorites.map(item=>{
                  if(item.type==="switch") {
                    return <Card
                      switchItem={item}
                      key={item.num_id}
                      url={url}
                      route={this.state.route}
                      reload={reload}
                      user={user}
                      areas={this.state.areas}
                    />
                  }
                  return <ProgCard
                    prog={item}
                    key={item.id}
                    url={url}
                    route={this.state.route}
                    reload={reload}
                    user={user}
                    areas={this.state.areas}
                  />
                })
              }
            </div>
            :<SwitchesList 
              area={this.state.route.startsWith("a_") ? Number(this.state.route.split("_")[1]) : this.state.route}
              url={url}
              reload={reload}
              switches={this.state.switches}
              route={this.state.route}
              user={user}
              openPop={this.openPop}
              closePop={this.closePop}
            />
          }
        </div>
      </div>
    );
  }
}
 
export default Home;